// app/javascript/controllers/autosave_controller.js
import {Controller} from "stimulus";

export default class extends Controller {
    static targets = ["form"];
    static values = {
        submitDuration: {type: Number, default: 1000},
        statusDuration: {type: Number, default: 2000},
        submittingText: {type: String, default: "Saving..."},
        successText: {type: String, default: "Saved!"},
        errorText: {type: String, default: "Unable to save."}
    }

    initialize() {
        this.timeout = null;
        this.data.set('submitDuration', 1000);
        console.log("autosaveTurbo controller")
    }

    autosave(event) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => this.submitForm(), this.data.get('submitDuration'));
    }

    submitForm() {
        console.log('saving...');
        this.formTarget.requestSubmit();
    }
}